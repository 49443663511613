@use "../../assets/styles/variables";
@use "./_resource.scss";

@mixin module {
  .filters {
    align-items: center;
    display: grid;
    gap: var(--g-gap);
    margin-bottom: calc(var(--g-gap) * -0.75);

    @container (min-width: 50em) {
      grid-template-columns: max-content 1fr auto;

      > :first-child {
        grid-column: 1 / span 2;
      }
    }

    p {
      margin: 0;
    }

    > * {
      max-width: 100%;
      overflow: auto;
    }
  }

  .searchInput {
    form {
      display: grid;
      grid-template-columns: [input-start] 1fr [loader] max-content [reset] max-content [submit] max-content [input-end];
      grid-auto-flow: row;
      width: 100%;
      max-width: 36em;

      > * {
        grid-row: 1;
      }
    }

    span {
      grid-column: loader;
    }

    button {
      align-self: center;
      background: none;
      border: none;
      padding: 0.5em;

      svg {
        display: block;
      }

      &[type="reset"] { grid-column: reset; }
      &[type="submit"] { grid-column: submit; }
    }

    input {
      border: none;
      border-bottom: 2px solid;
      color: inherit;
      font-family: inherit;
      font-size: 2.25em;
      grid-column: input-start / input-end;
      padding-right: 2em;

      &[type="search"]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }

      &[type="search"]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }

      &[type="search"]::-webkit-search-decoration,
      &[type="search"]::-webkit-search-cancel-button,
      &[type="search"]::-webkit-search-results-button,
      &[type="search"]::-webkit-search-results-decoration {
        display: none;
      }
    }
  }

  .engagementMode {
    display: grid;
    font-size: 1.4em;
    grid-auto-flow: column;
    gap: 0.25em;
    justify-content: start;

    label {
      --_button-bg: var(--button-bg, var(--c-goma-grey));
      --_button-fg: var(--button-fg, white);

      background: none;
      color: inherit;
      border: 2px solid;
      display: block;
      font-weight: 600;
      padding: 0.25em 1em;
      position: relative;
      transition: background 0.2s, color 0.2s;

      input {
        appearance: none;
        inset: 0;
        margin: 0;
        position: absolute;
        z-index: -1;
      }

      &:not(:has(:disabled)) {
        cursor: pointer;
      }

      &:has(:checked) {
        background: var(--_button-bg);
        border-color: var(--_button-bg);
        color: var(--_button-fg);
      }

      &:has(:disabled) {
        color: var(--c-goma-grey-disabled);
      }
    }
  }

  .pagination {
    text-align: center;
  }
}

@mixin styles {
  > header {
    text-align: center;

    > :where(h1, h2, h3, h4, h5, h6) {
      margin: 0;
    }

    > h2 {
      font-weight: 300;
      margin: calc(var(--g-gap) * -1.2) 0 var(--g-gap);
    }
  }

  .card__image {
    aspect-ratio: 1;
  }

  @at-root {
    .modal {
      @at-root dialog#{&} {
        --container-width: 76.8rem;
      }

      .breadcrumbs {
        display: none;
      }

      :where(.breadcrumbs + h1) {
        margin: 0;
      }

      iframe {
        margin: auto;
      }
    }
  }
}
