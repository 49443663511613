@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use 'helpers' as *;

$font-sizes: () !default;
$font-sizes-dynamic: null;
$font-sizes-mqs: null;
$root: (1rem * 100000, 1rem * -10000);

@function get-fluid-value(
  $min-size,
  $max-size,
  $min-viewport,
  $max-viewport,
  $modifier: 1
) {
  $max-viewport: $max-viewport * $modifier;

  @return calc(
    $min-size + strip-unit($max-size - $min-size) *
      ((100vw - $min-viewport) / strip-unit($max-viewport - $min-viewport))
  );
}

@function parse($font-sizes) {
  $is-root: true;
  $modifier: 1;
  $fluid: ();
  $static: ();
  $breakpoints: ();

  @each $prop-name, $size-definition in $font-sizes {
    $parsed: map.merge(
      (
        prop-name: $prop-name
      ),
      parse-size($size-definition, $modifier)
    );

    $font-sizes: map.set($font-sizes, $prop-name, $parsed);

    @if map.has-key($parsed, 'fluid-value') {
      $fluid: list.append($fluid, $parsed, $separator: comma);

      $breakpoints: map.set(
        $breakpoints,
        map.get($parsed, 'breakpoints'),
        list.append(
          map.get($breakpoints, map.get($parsed, 'breakpoints')) or (),
          $parsed,
          $separator: comma
        )
      );
    } @else {
      $static: list.append($static, $parsed, $separator: comma);
    }

    @if $is-root {
      $is-root: false;
      $modifier: math.div(
        map.get($font-sizes, $prop-name, 'min-size'),
        map.get($font-sizes, $prop-name, 'max-size')
      );
    }
  }

  @return (
    font-sizes: $font-sizes,
    static: $static,
    fluid: $fluid,
    breakpoints: $breakpoints
  );
}

@function parse-size($size-definition, $modifier: 1) {
  $params: unzip($size-definition, ('sizes', 'breakpoints'));
  $sizes: map.get($params, 'sizes');
  $breakpoints: list.join((20rem), map.get($params, 'breakpoints') or ());

  $params: (
    min-size: math.min($sizes...),
    max-size: math.max($sizes...),
    min-viewport: math.min($breakpoints...),
    max-viewport: math.max($breakpoints...),
    modifier: $modifier
  );

  @if list.length($sizes) > 1 {
    $params: map.set($params, 'fluid-value', get-fluid-value($params...));
  }

  $params: map.set($params, 'sizes', $sizes);
  $params: map.set($params, 'breakpoints', $breakpoints);

  @return $params;
}

$parsed: parse($font-sizes);
$font-sizes: map.get($parsed, 'font-sizes');
$static: map.get($parsed, 'static');
$fluid: map.get($parsed, 'fluid');
$breakpoints: map.get($parsed, 'breakpoints');

:root {
  @each $props in $static {
    --f-s-#{map.get($props, 'prop-name')}: #{map.get($props, 'min-size')};
  }

  @each $props in $fluid {
    --f-s-#{map.get($props, 'prop-name')}-fluid: #{map.get(
        $props,
        'fluid-value'
      )};
  }

  @each $props in $fluid {
    --f-s-#{map.get($props, 'prop-name')}: clamp(
      #{map.get($props, 'min-size')},
      var(--f-s-#{map.get($props, 'prop-name')}-fluid),
      #{map.get($props, 'max-size')}
    );
  }

  @supports not (font-size: clamp(1px, 1vw, 2px)) {
    @each $breakpoint, $values in $breakpoints {
      @each $props in $values {
        --f-s-#{map.get($props, 'prop-name')}: #{map.get($props, 'min-size')};
      }
    }

    @each $breakpoint, $values in $breakpoints {
      @media (min-width:#{list.nth($breakpoint, 1)}) and (max-width:#{list.nth($breakpoint, 2)}) {
        @each $props in $values {
          --f-s-#{map.get($props, 'prop-name')}: var(
            --f-s-#{map.get($props, 'prop-name')}-fluid
          );
        }
      }
    }

    @each $breakpoint, $values in $breakpoints {
      @media (min-width:#{list.nth($breakpoint, 2)}) {
        @each $props in $values {
          --f-s-#{map.get($props, 'prop-name')}: #{map.get($props, 'max-size')};
        }
      }
    }
  }
}
