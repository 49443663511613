@use 'modules/font' with (
  $font-sizes: (
    'root': (
      1rem,
      1.25rem 48rem
    ),
    'xl': (
      2.1875rem,
      3.75rem 120rem
    ),
    'lg': (
      1.75rem,
      2.25rem 120rem
    ),
    'md': (
      1.35rem,
      1.5rem 120rem
    ),
    'sm': 1.35rem,
    'xs': 0.75rem
  )
);

@mixin font-xl {
  font-size: var(--f-s-xl);
}
@mixin font-lg {
  font-size: var(--f-s-lg);
}
@mixin font-md {
  font-size: var(--f-s-md);
}
@mixin font-sm {
  font-size: var(--f-s-sm);
}
@mixin font-xs {
  font-size: var(--f-s-xs);
}

:root {
  --c-off-white: rgb(242, 242, 244);
  --c-goma-grey: rgb(60, 60, 60);
  --c-white: rgb(255, 255, 255);
  --c-mid-grey: rgb(95, 95, 95);
  --c-map-blue: rgb(101, 103, 127);
  --c-goma-grey-hover: rgb(92, 92, 92);
  --c-goma-grey-selected: rgb(42, 42, 44);
  --c-goma-grey-disabled: rgb(213, 218, 223);
  --c-caption-dark: var(--c-goma-grey);
  --c-caption-light: var(--c-white);

  --c-form-error: rgb(249, 82, 82);

  --g-col-size: 6rem;
  --g-col: minmax(min(var(--g-col-size), 100%), 1fr);
  --g-gap: 1.5rem;

  --container-width: 93rem;
  --gutter: calc((100vw - var(--container-width)) / 2);

  --slope: clamp(1rem, min(5vw, 12vh), 5rem);

  --button-bg: var(--c-goma-grey);
  --button-fg: var(--c-white);
  --selection-bg: var(--c-goma-grey);
  --selection-fg: var(--c-white);
}

@mixin reversed {
  --button-bg: var(--c-white);
  --button-fg: var(--c-goma-grey);
  --selection-bg: var(--c-white);
  --selection-fg: var(--c-goma-grey);
}

@mixin sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

@mixin sr-only-focusable {
  @include sr-only;

  &:focus,
  &:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  }
}

@mixin primary-grid {
  display: grid;
  gap: calc(var(--g-gap) * 2) var(--g-gap);
  grid-template-columns: [content-start] repeat(auto-fill, [col-start] var(--g-col) [col-end]) [content-end];

  @at-root :where(& > *) {
    grid-column: content;
  }
}

@mixin container {
  container-type: inline-size;
  display: grid;
  gap: calc(var(--g-gap) * 2) var(--g-gap);
  grid-template-columns:
    [edge-start] var(--gutter) [content-start] repeat(
      auto-fill,
      [col-start] var(--g-col) [col-end]
    )
    [content-end] var(--gutter) [edge-end];
  grid-column: 1/-1;

  @at-root :where(& > *) {
    grid-column: content;
  }
}

@mixin angled-container {
  clip-path: polygon(
    0 0,
    100% var(--slope),
    100% 100%,
    0 calc(100% - var(--slope))
  );
  padding: var(--slope) 0;
}

@mixin angled-container-top {
  clip-path: polygon(0 0, 100% var(--slope), 100% 100%, 0 100%);
  padding: var(--slope) 0 0;
}

@mixin angled-container-bottom {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - var(--slope)));
  padding: 0 0 var(--slope);
}
